@import '~@/assets/scss/main.scss';

#overview {
    @include media-breakpoint-up(lg) {
        height: 100%;
    }
}

.subscription-image {
    max-width: 15rem;
}
